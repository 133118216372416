.dropdown-item {
    color: white;
  }
  
  .dropdown-item:hover {
    background-color: #dddddd; /* Grey color on hover */
  }

  .btn-secondary {
    --bs-btn-color: #eb4328;
    --bs-btn-hover-color: #3d7bf8;
  }

  